<template>
    <div class="overlay" :class="{'showLoginPannel':$store.state.isShowLoginPanel && !$store.state.isLogin}">
        <div class="overlay-warp">
            <div class="overlay-open">

                <div class="login-warp">
                    <div class="login-head">
                        <div class="login-panel-close" @click="closePanel">
                            <img src="../assets/images/icon/common/close1.svg"/>
                        </div>
                    </div>
                    <div class="login-logo">
                        <img src="../assets/images/logo/ptn/logo01.png"/>
                    </div>
                    <div class="login-cont"  v-if="!$store.state.isMaintaining">
                        <div class="form-field">
                            <input type="text" placeholder="아이디" v-model="user.username" @keyup.enter="doLogin"/>
                        </div>
                        <div class="form-field">
                            <input type="password" placeholder="비밀번호" v-model="user.passwd" @keyup.enter="doLogin"/>
                        </div>
                        <div class="form-field">
                            <loading-login-comp v-if="clickedLogin"></loading-login-comp>
                            <button class="login" @click="doLogin"
                                    v-if="!clickedLogin">로그인
                            </button>
                        </div>
                        <div class="form-field">
                            <button class="register"
                                    @click="showRegisterPanel">회원가입
                            </button>
                        </div>
                        <div class="error-message"  v-show="erm != null">
                            <i class="fa fa-warning"></i>  {{erm}}
                        </div>
                    </div>
                    <div class="login-cont"  v-if="$store.state.isMaintaining"  v-html="$store.state.maintainingMessage">

                    </div>
                </div>

            </div>
        </div>

    </div>
</template>

<script>
    import {RECEIVE_HIDE_LOADING, RECEIVE_SHOW_LOADING, RECEIVE_USER_INFO} from "../store/mutation-types";
    import {loginRegisterMixin} from "../common/mixin";
    import {getSiteStatus, getUserInfo, login} from "../network/userRequest";
    import LoadingLoginComp from "./LoadingLoginComp";

    export default {
        name: "LoginComp",
        components: {LoadingLoginComp},
        mixins: [loginRegisterMixin],
        data() {
            return {
                user: {
                    username: '',
                    passwd: '',
                },
                isShowMessage: false,
                content: '',
                erm: null,
                telMessage: null,
                clickedLogin: false
            }
        },
        methods: {
            showRegisterPanel() {
                // if (!this.$store.state.isLogin) {
                //     this.$store.state.isShowRegisterPanel = true
                //     this.$store.state.isShowLoginPanel = false
                // }
                this.$store.state.isShowRegisterPanel = false
                this.$store.state.isShowLoginPanel = false
                this.$router.push('/agentcode')
            },
            closePanel() {
                this.$store.state.isShowLoginPanel = false;
                this.$store.state.isShowRegisterPanel = false
            },
            doLogin() {
                this.clickedLogin = true
                // if (!this.testRegxUserName) {
                //     this.$swal({
                //         title: '아이디는 4-15자리 영문,숫자조합입니다',
                //         type: 'error',
                //         showCancelButton: false,
                //         showConfirmButton: true
                //     })
                //     return false;
                // }
                // if (!this.testRegxPasswd) {
                //     this.$swal({
                //         title: '비밀번호는 4-20자리 입니다',
                //         type: 'error',
                //         showCancelButton: false,
                //         showConfirmButton: true
                //     })
                //     return false;
                // }
                login(this.user).then(res => {
                    if (res.data.success) {
                        getUserInfo().then(res => {
                            this.$store.state.isLogin = true;
                            this.$store.state.isShowLoginPanel = false
                            this.$store.state.isShowRegisterPanel = false
                            const userInfo = res.data.data
                            this.$store.commit(RECEIVE_USER_INFO, {userInfo});
                            window.sessionStorage.setItem("isLogin", 'true');
                        }, err => {
                            console.log(err)
                        })
                    } else {
                        this.erm = res.data.msg
                    }
                    this.clickedLogin = false;
                })
            },
            donotlogin() {
                this.telMessage = 'Telegram: @ 로 연락주세요'
            },

        },
        created() {

        },
        computed: {
            allowUsername() {
                return /^[a-z][0-9a-z]{3,20}$/.test(this.username)
            },
            allowPasswd() {
                return /^.{3,30}$/.test(this.passwd)
            }
        }
    }
</script>

<style scoped>

    @media screen and (max-width: 1024px) {
        .login-warp {
            width: 375px!important;
            height: 520px;
        }

    }

    .showLoginPannel {
        transform: translateY(0%);
    }

    .login-warp {
        width: 500px;
        height: 520px;
        background-color: var(--loginWarpBg);
        color: #fff9ff;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        border: 2px solid #ffffff;
        border-radius: 15px;
    }

    .login-warp .login-head {
        background-color: var(--loginWarpHeadBg);
        color: #fff;
        height: 40px;
        line-height: 40px;
        padding: 0 16px;
        position: relative;
        text-transform: uppercase;
        width: 100%;
        z-index: 5002;
        margin-top: 3px;
        border-radius: 20px;
    }

    .login-warp .login-head h4 {
        -webkit-align-items: center;
        align-items: center;
        display: -webkit-inline-flex;
        display: inline-flex;
        font-size: 14px;
        font-weight: 700;
        -webkit-justify-content: center;
        justify-content: center;
        margin: 0;
        vertical-align: middle;
    }

    .login-panel-close {
        position: absolute;
        top: 10px;
        right: 10px;
        width: 30px;
        height: 30px;
        padding: 2px;
        cursor: pointer;
        --border: 1px solid #fff9ff;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 1px;
        font-weight: normal;
    }

    .login-panel-close img {
        height: 30px;
    }
    .login-warp .login-logo img{
        width: 246px;
    }

    .login-warp .login-cont {
        width: 80%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        overflow: hidden;
        z-index: 5001;
        margin-top: 20px;
    }

    .login-warp .login-cont .form-field{
        width: 100%;
        box-sizing: border-box;
        padding: 5px 0;
    }
    .login-warp .login-cont .form-field input{
        background-color: #186764;
        border: 0;
        --border-bottom: 1px solid #a3a1a7;
        border-radius: 5px;
        color: #fff9ff;
        font-size: 15px;
        height: 58px;
        line-height: 58px;
        outline: 0;
        padding: 0;
        width: 100%;
        text-align: center;
    }
    .form-field button{
        width: 100%;
        height: 58px;
        line-height: 58px;
        font-size: 20px;
        font-weight: bold;

        border-radius: 5px;
    }
    .form-field .login{
        background: linear-gradient(180deg, #0de2dc,#01bab4,#01bab4, #4de4df);
        color: #353539;
    }
    .form-field .register{
        background: linear-gradient(180deg, #88fffb, #88fffb);
        color: #353539;
    }

    .maintaining {
        text-align: center;
        box-sizing: border-box;
        padding-top: 30px;
    }

    .error-message {
        text-align: center;
        font-size: 14px;
        box-sizing: border-box;
        padding: 10px 0;
        color: #f61515;
        animation: sansan 1200ms infinite;
        -webkit-animation: sansan 1200ms infinite;
    }

    @keyframes sansan {
        from {
            opacity: 1.0;
        }
        70% {
            opacity: 0.8;
        }
        50% {
            opacity: 0.6;
        }
        to {
            opacity: 1.0;
        }
    }
    @-webkit-keyframes sansan {
        from {
            opacity: 1.0;
        }
        50% {
            opacity: 0.4;
        }
        to {
            opacity: 1.0;
        }
    }

</style>